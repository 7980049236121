// import type { NextPage } from 'next'
// import Image from 'next/image'
// import { useEffect, useState } from 'react'
// import { IconContainer, ImageContainer } from '../components/ImageContainer'

// import Accordion from '@mui/material/Accordion'
// import AccordionDetails from '@mui/material/AccordionDetails'
// import AccordionSummary from '@mui/material/AccordionSummary'
// import { withIronSessionSsr } from 'iron-session/next'
// import Link from 'next/link'
// import { PreorderButton } from '../components/Preorder'
// import {
//   DesktopMediaQuery,
//   TabletOrMobileMediaQuery,
// } from '../components/ResponsiveWrappers'
// import RetainQueryLink from '../components/RetainQueryLink'
// import { StayTuned } from '../components/StayTuned'
// import { sessionOptions } from '../lib/session'
// import { content } from '../lib/utils/content'
// import { targetBlank } from '../utils/linkUtils'

// const clamp = (num: number, min: number, max: number) =>
//   Math.min(Math.max(num, min), max)

// interface HomeProps {
//   twitterId: string
// }

// const Home: NextPage<HomeProps> = ({ twitterId }) => {
//   const [scrollPerc, setScrollPerc] = useState(0)

//   useEffect(() => {
//     // Disables right click to prevent downloading of images/videos
//     window.oncontextmenu = function () {
//       return false
//     }

//     const handleScroll = () => {
//       const sm = window.innerWidth >= 640
//       const START = sm ? 600 : 200
//       const END = sm ? 1400 : 1400

//       setScrollPerc(clamp((window.scrollY - START) / (END - START), 0, 1))
//     }

//     window.addEventListener('scroll', handleScroll)
//     return () => {
//       window.removeEventListener('scroll', handleScroll)
//     }
//   }, [])

//   const firstSectionArray = content.firstSection.split(' ')
//   const textIndex = Math.floor(scrollPerc * firstSectionArray.length)
//   const textA = firstSectionArray.slice(0, textIndex).join(' ') + ' '
//   const textB = firstSectionArray.slice(textIndex).join(' ')
//   const [expanded, setExpanded] = useState<string | false>('panel1')

//   const handleChange =
//     (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
//       setExpanded(newExpanded ? panel : false)
//     }

//   return (
//     <div className="flex flex-col">
//       <section className="relative=">
//         <div className="flex flex-col items-center justify-between ">
//           <div className="md:hidden min-h-[500px] h-auto max-w-[400px] relative md:mb-[-60px] mt-[-40px] md:mt-[-80px]">
//             <video
//               src="https://dk29pix7jljmj.cloudfront.net/chapter_2_hero_tall.mp4"
//               disableRemotePlayback
//               autoPlay
//               loop
//               muted
//               playsInline
//               poster="/chapter_2_hero_tall.png"
//             />
//           </div>
//           <div className="hidden md:block min-h-[500px] h-auto max w-full relative md:mb-[-60px] md:mt-[-80px]">
//             <video
//               src="https://dk29pix7jljmj.cloudfront.net/chapter_2_hero.mp4"
//               autoPlay
//               loop
//               muted
//               playsInline
//               poster="/chapter_2_hero.png"
//             />
//           </div>
//           <div className="h-[123px] w-full mb-[56px] relative flex flex-col items-center ">
//             <div className="h-[123px] w-[21px]">
//               <Image src={'/scroll.svg'} fill alt="product" />
//             </div>
//           </div>
//           <div className="w-full h-[1200px] sm:h-[1150px] text-[32px] md:text-[64px] leading-[50px] md:leading-[80px] font-normal tracking-[-0.09px] md:tracking-[-1.28px] block">
//             <p className="sticky top-[20px] sm:top-[50px]">
//               <span className="opacity-100">{textA}</span>
//               <span className="opacity-20">{textB}</span>
//             </p>
//           </div>
//           <div className="h-[123px] w-full mb-[48px] mt-[48px]">
//             <ImageContainer src={'/scroll.svg'} priority={true} />
//           </div>
//           <div className="w-full text-[50px] md:text-[100px] leading-[60px] md:leading-[80px] font-normal text-center tracking-[-0.1px] block">
//             Preorder
//           </div>
//           <div className="w-full text-[20px] md:text-[28px] md:leading-[34px] font-normal text-center tracking-[-0.1px] block mb-[36px]  mt-[23px] md:mt-[36px]">
//             Chapter 2: Shipping 2025
//           </div>
//           <div className="flex w-full sm:max-w-[700px] tracking-wider sm:tracking-tight sm:text-2xl">
//             <div className="relative border-l border-gray-4 mx-4 my-10">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="27"
//                 height="27"
//                 viewBox="0 0 27 27"
//                 fill="none"
//                 className="green-circle absolute -left-[14px] top-[65px]"
//               >
//                 <circle
//                   cx="13.5"
//                   cy="13.5"
//                   r="13.25"
//                   stroke="url(#paint0_linear_761_2513)"
//                   strokeWidth="0.5"
//                 />
//                 <defs>
//                   <linearGradient
//                     id="paint0_linear_761_2513"
//                     x1="11.5714"
//                     y1="-16.875"
//                     x2="16.875"
//                     y2="24.5893"
//                     gradientUnits="userSpaceOnUse"
//                   >
//                     <stop stopColor="#19FB9B" />
//                     <stop offset="1" stopColor="#19FB9B" stopOpacity="0" />
//                   </linearGradient>
//                 </defs>
//               </svg>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="11"
//                 height="11"
//                 viewBox="0 0 11 11"
//                 fill="none"
//                 className="green-dot absolute -left-[6px] top-[74px]"
//               >
//                 <circle cx="5.5" cy="5.5" r="5.5" fill="#19FB9B" />
//               </svg>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="67"
//                 height="67"
//                 viewBox="0 0 67 67"
//                 fill="none"
//                 className="green-gradient absolute -left-[34px] top-[45px]"
//               >
//                 <g opacity="0.4" filter="url(#filter0_f_761_2509)">
//                   <circle cx="33.5" cy="33.5" r="13.5" fill="#19FB9B" />
//                 </g>
//                 <defs>
//                   <filter
//                     id="filter0_f_761_2509"
//                     x="0"
//                     y="0"
//                     width="67"
//                     height="67"
//                     filterUnits="userSpaceOnUse"
//                     colorInterpolationFilters="sRGB"
//                   >
//                     <feFlood floodOpacity="0" result="BackgroundImageFix" />
//                     <feBlend
//                       mode="normal"
//                       in="SourceGraphic"
//                       in2="BackgroundImageFix"
//                       result="shape"
//                     />
//                     <feGaussianBlur
//                       stdDeviation="10"
//                       result="effect1_foregroundBlur_761_2509"
//                     />
//                   </filter>
//                 </defs>
//               </svg>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="17"
//                 height="17"
//                 viewBox="0 0 17 17"
//                 fill="none"
//                 className="gray-dot-1 absolute -left-[9px] -top-[12px] sm:-top-[5px]"
//               >
//                 <circle
//                   cx="8.5"
//                   cy="8.5"
//                   r="6.5"
//                   fill="#3A4047"
//                   stroke="#090B0F"
//                   strokeWidth="4"
//                 />
//               </svg>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="17"
//                 height="17"
//                 viewBox="0 0 17 17"
//                 fill="none"
//                 className="gray-dot-2 absolute -left-[9px] top-[170px] sm:top-[190px]"
//               >
//                 <circle
//                   cx="8.5"
//                   cy="8.5"
//                   r="6.5"
//                   fill="#3A4047"
//                   stroke="#090B0F"
//                   strokeWidth="4"
//                 />
//               </svg>
//             </div>
//             <div className="ml-4 w-full">
//               <div className="text-gray-4 my-6 font-medium text-[17px] md:text-[28px]">
//                 Founder Window
//               </div>

//               <hr className="border-gray-55" />

//               <div className="my-6">
//                 <div className="leading-6 text-[13px] md:text-[17px]">
//                   Open •{' '}
//                   <span className="text-solana-green">Closing soon!</span>
//                 </div>
//                 <div className="flex justify-between mt-2">
//                   <div className="font-medium text-[17px] md:text-[28px]">
//                     Early Adopter Window
//                   </div>
//                   <div className=" text-[17px] md:text-[28px]">$500.00*</div>
//                 </div>
//               </div>

//               <hr className="border-gray-55" />

//               <div className="text-gray-4 my-6 font-medium text-[17px] md:text-[28px]">
//                 Supporter Window
//               </div>
//             </div>
//           </div>
//           <div className="sm:max-w-[700px] text-[13px] md:text-[17px] leading-[22px] md:leading-[28px] font-normal text-center tracking-[-0.1px] block mt-8 mx-10 mb-[10px] text-gray-3">
//             *Generally, customers are not entitled to a refund of the deposit.
//             However, if Solana Mobile determines not to move forward with the
//             product, customers will be refunded. If the product ships, there
//             will be a return policy associated with the product and refunds will
//             be subject to that return policy.
//           </div>
//           <div className="w-full  text-center ">
//             <PreorderButton className="py-4 rounded-[50px]" />
//           </div>
//         </div>
//       </section>
//       <section className="relative mt-[70px]">
//         <div className="border-t border-[#3A4047] "></div>
//         {/* <div className="flex md:flex-row flex-col-reverse bg-[#191E23] items-center w-full h-auto px-[16px] md:px-[30px] md:gap-4 lg:px-[69px] pb-[41px] pt-[38px] mt-[45px] rounded-3xl justify-between">
//           <div className="max-w-[412px] w-full max-h-[680px] h-auto text-center">
//             <Image
//               className="w-full"
//               src="/hometablehq8.png"
//               alt="leaderboard-example"
//               quality={100}
//               width={412}
//               height={410}
//             />
//           </div>
//           <div className="flex text-center md:text-left flex-col max-w-[400px] md:max-w-full  ">
//             <span className=" text-[32px] md:text-[44px] md:leading-[48px] mb-[22px]">
//               Become a top referrer
//             </span>
//             <span className="max-w-[480px] md:text-[20px] text-[17px] leading-7 text-[#818C99] mb-[64px]">
//               Climb the leaderboard when people preorder Chapter 2 using your
//               referral link. Have a chance at potential rewards from ecosystem
//               teams in the process.
//             </span>

//             <PreorderButton text="Preorder and start referring" />

//             <span className="text-[15px] text-[#818C99] leading-[24px] mt-[22px]">
//               Or, get a referral link from the{' '}
//               <RetainQueryLink href={'/leaderboard'} className="text-[#19FB9B]">
//                 Leaderboard page
//               </RetainQueryLink>
//               {' '} without preordering.
//             </span>
//           </div>
//         </div> */}
//         <div className="custom-bg-border-div flex flex-col h-[340px] justify-evenly items-center mt-[72px]">
//           <div className="text-[36px] leading-[50px] md:max-w-[80%] tracking-[-0.09px] md:text-[65px] md:leading-[80px] md:tracking-[-0.162px] w-[270px] text-center md:w-full">
//             Lock in your preorder
//           </div>
//           <span className="text-[17px] md:text-[21px] leading-[24px]">
//             Early Adopter Window •{' '}
//             <span className="text-[#19FB9B]">Closing Soon!</span>
//           </span>
//           <PreorderButton className="py-4 rounded-[50px]" />
//         </div>
//       </section>
//       <section className="mt-[108px] md:mt-[180px]">
//         <div className="text-[50px] leading-[60px] tracking-[-0.5px] mb-[63px] md:text-[100px] md:leading-[80px] md:tracking-[-1px] md:mb-[70px]">
//           FAQs
//         </div>
//         <div className="flex flex-col items-center justify-between">
//           {content.faq.map((item, index) => {
//             return (
//               <Accordion
//                 key={`collapse-${index}`}
//                 className="!w-full !text-white !bg-[#07080D] !border-b-[1px] !border-[#3A4047] !m-0"
//                 expanded={expanded === `panel${index + 1}`}
//                 onChange={handleChange(`panel${index + 1}`)}
//               >
//                 <AccordionSummary
//                   className="!p-0 !mt-[24px] !mb-[24px] !text-[20px] !h-[48px] !min-h-0 !leading-[24px] !md:text-[28px] !md:leading-[34px] !font-light items-center !gap-5 "
//                   expandIcon={
//                     <IconContainer
//                       nolg={true}
//                       src="/expand_circle_up.svg"
//                       className="!text-white"
//                     />
//                   }
//                   aria-controls={`panel1a-content-${index}`}
//                   id={`panel1a-header-${index}`}
//                 >
//                   {item.question}
//                 </AccordionSummary>
//                 <AccordionDetails className="!text-[#818C99] !text-[17px] !leading-[28px] !md:text-[22px] !md:leading-[36px] !p-0 !mb-[48px] max-w-[80%] lg:max-w-[90%]">
//                   {item.answer}
//                 </AccordionDetails>
//               </Accordion>
//             )
//           })}
//         </div>
//         <span className="text-[13px] leading-[28px] text-center tracking-[-0.1px] block mt-[48px] text-[#818C99]">
//           These FAQs are provided for your convenience only and are qualified by
//           and subject in their entirety to the{' '}
//           <Link
//             href="/deposit-waitlist-agreement-web"
//             {...targetBlank}
//             className="!text-[#19FB9F] !visited:text-[#19FB9F]"
//           >
//             Deposit Agreement
//           </Link>
//           .
//         </span>
//       </section>
//       <section className="h-[240px] !border-b-[1px] !border-[#3A4047]"></section>
//       <section className="mb-[68px] mt-[48px] ">
//         <StayTuned />
//       </section>
//     </div>
//   )
// }

// export const getServerSideProps = withIronSessionSsr(async (context) => {
//   const { twitterId, referral } = context.req.session

//   return {
//     props: {
//       twitterId: twitterId || null,
//       referral: referral || null,
//     },
//   }
// }, sessionOptions)

import Preorder from "./preorder"

export default Preorder
